import React from "react";
import {SocialComponent } from "../utils/links";
import logo from "../assets/image/logo_bianco.png";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
          <div
            className="logo-img"
            style={{
              background: `url(${logo})`,
              backgroundPosition: "center",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
            }}
            />
            <h5>CONTATTI</h5>
            <br></br>
            <p>Lifetronic S.r.l</p>
            <p>Via Meucci, 1/E</p>
            <p>CAP 56121</p>
            <p>Pisa</p>
            <br></br>
            <p>Tel. +39.050.877757</p>
            <p>Fax   +39.050.877756</p>
            <p>info@lifetronic.it</p>
            <br></br>
            <br></br>
            <p>@Copyright - www.lifetronic.it - Partita IVA 01886150505 - Codice SDI: SZLUBAI</p>
        </div>
        <div className="footer-social">
            <SocialComponent classSocial="socialTop" />
        </div>
    </footer>
  );
};

export default Footer;
