import React, { useState } from "react";
import $ from "jquery";

const AboutCard = ({
    id,
    name,
    role,
    description,
    link,
    image,
    colored_image,
}) => {
    const [backImg, setBackImg] = useState(0);
    const [ts, setTs] = useState(0);

    var active = 0;
    var unitPhoto = 0;
    const handeMouseEnter = (event) => {
        event.stopPropagation();
        setTs(Date.now());
        setBackImg(colored_image);
    };
    const handleTouchStart = (event) => {
        event.stopPropagation();
        setTs(Date.now());
        setBackImg(colored_image);
    };

    const handleMouseMove = (event) => {
        if (Date.now() > ts + 500) {
            unitPhoto = 100 / (image.length - 1);
            var offset = $("#" + event.target.attributes.id.value).offset();
            let x = event.pageX - offset.left;
            let w = $("#" + event.target.attributes.id.value).width();
            x = (x * 100) / w;
            let index = Math.floor(x / unitPhoto);

            if (index >= image.length) {
                index = image.length - 1;
            }
            if (index < 0) {
                return;
            }
            if (index !== active || index != colored_image) {
                if (index == 0) {
                    index = colored_image;
                }
                setBackImg(index);
                active = index;
            }
        }
    };
    const handleTouchMove = (event) => {
        if (Date.now() > ts + 500) {
            unitPhoto = 100 / (image.length - 1);
            var offset = $("#" + event.target.attributes.id.value).offset();
            let x = event.touches[0].pageX - offset.left;
            let w = $("#" + event.target.attributes.id.value).width();
            x = (x * 100) / w;
            let index = Math.floor(x / unitPhoto);

            if (index >= image.length - 1) {
                index = image.length - 2;
            }
            if (index < 0) {
                return;
            }
            if (index !== active || index !== colored_image) {
                if (index == 0) {
                    index = colored_image;
                }
                setBackImg(index);
                active = index;
            }
        }
    };
    const handeMouseOut = (event) => {
        event.stopPropagation();
        setBackImg(0);
    };

    $(document).on("touchend", function () {
        setBackImg(0);
    });

    return (
        <div className="aboutDiv">
            <div
                id={id}
                class="aboutImageContainer"
                onMouseEnter={(ev) => handeMouseEnter(ev)}
                onMouseLeave={(ev) => handeMouseOut(ev)}
                onMouseMove={(ev) => handleMouseMove(ev)}
                onTouchStart={(ev) => handleTouchStart(ev)}
                onTouchMove={(ev) => handleTouchMove(ev)}
            >
                {image.map((img, index) => { 
                    const zIndex = index == backImg ? 10000 : 10;
                    const opacity = index == backImg ? 1.0 : 0.0;
                    return (
                        <div
                            id={`image-${id}-${index}`}
                            key={`image-${id}-${index}`}
                            style={{
                                background: `url(${img})`,
                                zIndex,
                                opacity
                            }}
                            className="profileImage"
                        ></div>
                    )
                })}
            </div>
            <h3 className="profileName">{name}</h3>
            <p className="profileRole">{role}</p>
        </div>
    );
};

export default AboutCard;
