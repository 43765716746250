import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import { Link } from "react-router-dom";

const AutoplaySlider = withAutoplay(AwesomeSlider);

const Slider = () => {
   
    return(
        <AutoplaySlider
            play={true}
            cancelOnInteraction={false}
            interval={6000}
            className="sliderHome home"
            
        >
            <div className="image__description leftFloat" data-alt="Prima immagine dello slider in homepage" data-src="/image/slider/home/home_1.webp">
                <div>
                        <h2 className="sliderTitleTop">GARANTIAMO SOLUZIONI ALL'AVANGUARDIA</h2>
                        <Link to="/prodotti_servizi/" className="serviceButton">SERVIZI</Link>
                        <h3 className="sliderTitleBottom">“Se la domotica fosse un vestito noi saremmo i suoi sarti”– Lifetronic.</h3>
                </div>
            </div>
            <div className="image__description leftFloat" data-alt="Seconda immagine dello slider in homepage" data-src="/image/slider/home/home_2.webp">
                <div>
                        <h2 className="sliderTitleTop">GARANTIAMO SOLUZIONI ALL'AVANGUARDIA</h2>
                        <Link to="/prodotti_servizi/" className="serviceButton">SERVIZI</Link>
                        <h3 className="sliderTitleBottom">“Il software è una grande combinazione tra arte e ingegneria” – Bill Gates.</h3>
                        
                </div>
            </div>
            <div className="image__description leftFloat" data-alt="Terza immagine dello slider in homepage" data-src="/image/slider/home/home_3.webp">
                <div>
                        <h2 className="sliderTitleTop">GARANTIAMO SOLUZIONI ALL'AVANGUARDIA</h2>
                        <Link to="/prodotti_servizi/" className="serviceButton">SERVIZI</Link>
                        <h3 className="sliderTitleBottom">“Sono la passione e la curiosità a guidare l’innovazione” – Dan Brown.</h3>
                </div>
            </div>

        </AutoplaySlider>
)
};

export default Slider;
