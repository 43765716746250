import React, {useState, useEffect} from "react";
import { AiFillHome, AiFillTwitterSquare, AiFillLinkedin } from "react-icons/ai";
import { RiTeamFill } from "react-icons/ri";
import { useGlobalContext } from "../context";
import $ from 'jquery';
import { useLocation } from 'react-router-dom'

import {
  FaFacebookSquare,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const links = [
  {
    id: 1,
    url: "/prodotti_servizi/",
    text: "Prodotti e Servizi",
    icon: <AiFillHome className="nav-icon" />,
  },
  {
    id: 2,
    url: "/portfolio",
    text: "Portfolio",
    icon: <RiTeamFill className="nav-icon" />,
  },
  {
    id: 3,
    url: "/chi_siamo",
    text: "Chi Siamo",
    icon: <RiTeamFill className="nav-icon" />,
  },
];

const LinkComponent = ({ classLink }) => {
  const { closeSidebar } = useGlobalContext();
  var id = '';

  const [activeId, setActiveId] = useState(id);
  const changePage = (id) => {
    setActiveId(id);
  }
  
  $( "body" ).on( "click", ".nav-brand", function() {
    setActiveId(0);
  });
  const location = useLocation();
  useEffect(() => {
    links.map((link) => {
      if(link.url == location.pathname.split("?")[0]){
        id= link.id
        setActiveId(id);
      }
    })
    
  })
  return (
    <ul className={classLink}>
      {links.map((link) => {
        return (
          <Link
            key={link.text}
            to={link.url}
            className="nav-item"
            onClick={closeSidebar}
          >
            <div onClick={() => changePage(link.id)} className="nav-link">
              <h5 className={activeId === link.id ? "nav-text activeLink" : "nav-text"}>{link.text}</h5>
            </div>
          </Link>
        );
      })}
      
    </ul>
  );
};

const socialLink = [
  {
    url: "https://www.facebook.com/Lifetronic-srl-104885291490500",
    icon: <FaFacebookSquare id="facebook" className="social-icon" />,
  },
 
  {
    url: "https://mobile.twitter.com/LLifetronic",
    icon: <AiFillTwitterSquare id="twitter" className="social-icon" />,
  },
  {
    url: "https://www.linkedin.com/company/lifetronic-s.r.l./about/",
    icon: <AiFillLinkedin id="linkedin" className="social-icon" />,
  }
];

const SocialComponent = ({ classSocial }) => {
  return (
    <ul className={classSocial}>
      {socialLink.map((link) => {
        return (
          <li key={link.url} className="nav-item">
            <a href={link.url} alt={link.url} className="nav-link">
              {link.icon}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export { LinkComponent, SocialComponent };
