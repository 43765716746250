import React from "react";

const CurvedShape = (props) => {
  
  return (
      
      <div className="container-shapes">
        <svg className={props.cname} viewBox="0 0 1731 181" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_0_316)">
        <path d="M0.0324707 20H-188.719C-273.435 57.0359 0.0324707 20 0.0324707 20C0.0324707 20 1204.02 129 1390.76 129C1576.54 129 1673.77 80.9733 1713.17 43.4862C1722.23 34.8619 1727.6 26.7955 1731 20H0.0324707Z" fill="#B1B1B1"/>
        <path d="M306.716 153C100.713 153 16.404 64.3333 0 20H1731C1342.07 64.3333 512.719 153 306.716 153Z" fill="#004E6A" fill-opacity="0.76"/>
        </g>
        <defs>
        <filter id="filter0_d_0_316" x="-229" y="0" width="1984" height="181" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="12"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.17 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_316"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_316" result="shape"/>
        </filter>
        </defs>
        </svg>
      </div>
          
      
  );
};

export default CurvedShape;
