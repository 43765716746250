import React from "react";
import { Link } from "react-router-dom";

const HomeSector = ({ title, description, link, icon}) => {
  return (
        <div className="sectorDiv">

              <div className="iconWrap">
                <Link to={`${link}`}>
                    <div dangerouslySetInnerHTML={{ __html: icon }} className="sectorIconDiv">
                      
                    </div> 
                </Link>
              </div>
              <div className="sectorTextWrap">
                <div>
                  <h3 className="sectorTitle">{title}</h3>
                  <p style={{display:"flex", minHeight:"60px", fontSize: "12px", color:"#101010", marginLeft: "10px"}}>{description}</p>
                </div>
              </div>
        </div>
  );
};

export default HomeSector;