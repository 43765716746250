import React from "react";

const GalleryCaption = ({ selected = 0, title = [], description= [], area = 0 }) => {
  var title = title[selected];
  var data = description[selected];
  var hiddenTitle= '';
  if(title == "RISERVATO"){
    let regione = data[0].text.replace("<p>","");
    regione = regione.replace("</p>","");

    hiddenTitle = title +' - '+ regione;
    document.title =hiddenTitle;
  }else{
    document.title =title;
  }
  return (
    <div className="containerDivDescriptionPortflio">
      <div className="DivDescriptionPortflio">
          <p id ={"description_portfolio_"+area} className="paragDescriptionPortflio" >
            <h3>{title}</h3>
            <p style={{display:'none'}}>{hiddenTitle}</p>
            <hr></hr>
            {
                data.map((val) => (
                 <div className="rowDescriptionPortfolio">
                   <div className="divIndexPortfolio" dangerouslySetInnerHTML={{ __html: val.index }} >

                   </div>
                   <div className="divTextPortfolio" dangerouslySetInnerHTML={{ __html: val.text }} >
                     
                   </div>
                 </div>
            ))}
          </p>
      </div>
  </div>

  );
};

export default GalleryCaption;
