import React, {useEffect} from "react";
import useTitle from "../useTitle";
import {CurvedShape, AboutCard} from "../components";
import members from '../members.json';
import ContactForm from "../components/Form";
import MyMap from "../components/Map";
import { Helmet } from "react-helmet";

const AboutScreen = () => {
  

    useTitle("Chi Siamo");
    window.scrollTo(0, 0)
    return (
        <>
            <Helmet>
              <meta charSet="utf-8" />
              <meta name="description" content="Chi siamo e di cosa ci occupiamo all'interno di Lifetronic: le nostre passioni dentro e fuori dal lavoro." />
            </Helmet>
            <div className="aboutHeaderImage"  style={{ 
            backgroundImage: `url("/image/team.webp")`,
            backgroundSize: 'cover'
            }}>
                <div className="titleWhyChoose">
                    <h1>IL NOSTRO TEAM</h1>
                    <h6 className="aboutSubTitle">Siamo un team di professionisti, sempre pronti a trovare la soluzione migliore per te</h6>
                </div>
            </div>
            <CurvedShape cname="svg-shapes"/>
            <div className="aboutCardContainer">
                {Object.entries(members).map(([key,value])=>{
                    return(<AboutCard key={key} id={key} {...value}/>)
                    
                })}
            </div>
            <h2 className="whereTitle">Dove Siamo</h2>
            <MyMap/>
            <h2 className="whereTitle">Inviaci una mail</h2>
            <ContactForm/> 
            <CurvedShape cname="svg-shapes-rotated"/>
        </>
    );
};

export default AboutScreen;
