import React from "react";
import {StaticSlider} from "../components";
import $ from 'jquery';

const PortfolioArea = ({area}) => {
    var className = 'block';
    if(area != 'sviluppo_software'){
        className = 'none';
    }
    var clientText = "CLIENTI";
    const importAll= (r) => {
        let images = {};
        r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
        return images;
    }
    if(area === 'sviluppo_software'){
        var client = importAll(require.context('../assets/image/client/sviluppo_software/', false, /\.(png|jpe?g|svg)$/));
    }
    if(area === 'domotica'){
        clientText= "PARTNER"
        var client = importAll(require.context('../assets/image/client/domotica/', false, /\.(png|jpe?g|svg)$/));
    }
    if(area === 'digital_signage'){
        var client = importAll(require.context('../assets/image/client/digital_signage/', false, /\.(png|jpe?g|svg)$/));
    }
    if(area === 'ricerca_e_sviluppo'){
        clientText= "PARTNER"
        var client = importAll(require.context('../assets/image/client/ricerca_e_sviluppo/', false, /\.(png|jpe?g|svg)$/));
    }
    return (
            <div style={{display:className,}}id={area}>
                <StaticSlider slider={"portfolio_"+area} />
                <div className="containerDivDescriptionPortflio">
                    <div className="DivDescriptionPortflio">
                        <p id ={"description_portfolio_"+area} className="paragDescriptionPortflio"></p>
                    </div>
                </div>
                <h3 className="titleClient">ALCUNI DEI NOSTRI {clientText}</h3>
                <div className="divClientWrap">
                    <div className="divClient">
                        {
                            Object.keys(client).map((val) => (
                                <div className={"clientLogo "+ area+"_logo"}  style={{ 
                                    backgroundImage: `url(/image/client/${area+"/"+val})` 
                                }} ></div>
                            ))}
                    </div>
                </div>
            </div>
            
    );
};

export default PortfolioArea;