import React from "react";
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import { Link } from "react-router-dom";
import { IoIosRedo} from "react-icons/io";

const AutoplaySlider = withAutoplay(AwesomeSlider);

const productPage = ({ selected = 0, captions = [], area = 0 }) => {
    if(selected == 0){
        return (
            <div className="containerDivDescriptionProduct">
                
                <h2 className="portfolioLink">Vuoi dare un'occhiata ai nostri progetti?</h2>
                <div className="buttonRowProduct">
                    <Link to="/portfolio?goup=y" className="ourProjectButton">I NOSTRI PROGETTI</Link>
                    
                </div>
            </div>
            
          );
    }else if(selected == 1){
        return (
            <div className="containerDivDescriptionProduct">
                <div className="containerAreaData">
                    <h2 id="sviluppo_title" title="sviluppo software" className="areaTitle" style={{
                                                    borderLeft: `4px solid #b59852`,
                                                }}>Sviluppo Software</h2>
                    <h4 className="areaService" style={{
                                                    color: `#b59852`,
                                                }}>Servizi offerti: Sviluppo, Security Assessment, Consulenza.</h4>
                    <p className="areaDescription">Con il termine Sviluppo Software indichiamo quel processo mediante il quale viene creato un software autonomo o individuale utilizzando un linguaggio di programmazione specifico. Lo sviluppo di software può anche essere chiamato sviluppo di applicazioni e progettazione di software. Lifetronic crea Software e App progettate e personalizzate sul tuo business e secondo le tue esigenze. Grazie alle nostre soluzioni realizzate su misura per il tuo business, avrai la certezza di avere un prodotto calibrato sulle tue specifiche esigenze, senza dover rinunciare a nulla. </p>
                </div>
                <AutoplaySlider 
                    play={true}
                    cancelOnInteraction={true}
                    interval={5000}
                    className="sliderSubproduct" 
                >
                    <div className="slide_prodotti" data-alt="Slider sviluppo software prima immagine" data-src="/image/slider/area/sviluppo_software/Slider_SviluppoSoftware.webp"></div>
                    <div className="slide_prodotti" data-alt="Slider sviluppo software seconda immagine" data-src="/image/slider/area/sviluppo_software/Slider_SviluppoSoftware_2.webp"></div>
                    <div className="slide_prodotti" data-alt="Slider sviluppo software terza immagine" data-src="/image/slider/area/sviluppo_software/Slider_SviluppoSfotware_3.webp"></div>
                </AutoplaySlider>
                <h2 className="portfolioLink">Vuoi dare un'occhiata ai nostri software?</h2>
                <div className="buttonRowProduct">
                    <Link to="/portfolio/?area=sviluppo&goup=y" className="ourProjectButton">I NOSTRI PROGETTI</Link>
                </div>
            </div>
        );
    }else if(selected == 2){
        return (
            <div className="containerDivDescriptionProduct">
                <div className="containerAreaData">
                    <h1 id="domotica_title" title="domotica" className="areaTitle" style={{
                                                    borderLeft: `4px solid #004A67`,
                                                }}>Domotica</h1>
                    <h4 className="areaService" style={{
                                                    color: `#004A67`,
                                                }}>Servizi offerti: Sviluppo impianti, Assistenza Security Assessment, Consulenza.</h4>
                    <p className="areaDescription">La domotica è l’insieme di sistemi e dispositivi che permettono di migliorare il comfort e l’efficienza di un’abitazione. Il sistema domotico è in grado di far dialogare tutti gli impianti presenti secondo le esigenze del cliente. È importante rivolgersi a un’azienda specializzata nel settore che sia in grado di progettare al meglio l’impianto e garantire affidabilità e durata del sistema.In questo caso, Lifetronic dispone di prodotti leader nel mercato, di cui si fa interprete con la sua esperienza e su cui sviluppa nuovi servizi e tecnologie che gli permettono di trovare soluzioni ai problemi di controllo.Lifetronic, come system integrator, seleziona con estrema attenzione i prodotti che distribuisce, utilizzando solo marchi di aziende che garantiscono affidabilità, professionalità e continuità nel servizio erogato.</p>
                </div>
                <AutoplaySlider 
                    play={true}
                    cancelOnInteraction={true}
                    interval={5000}
                    className="sliderSubproduct" 
                >
                    <div className="slide_prodotti" data-alt="Slider domotica prima immagine" data-src="/image/slider/area/domotica/Slider_Domotica.webp"></div>
                    <div className="slide_prodotti" data-alt="Slider domotica seconda immagine" data-src="/image/slider/area/domotica/Slider_Domotica_2.webp"></div>
                    <div className="slide_prodotti" data-alt="Slider domotica terza immagine" data-src="/image/slider/area/domotica/Slider_Domotica_3.webp"></div>
                </AutoplaySlider>
                <h1 className="ourAppTitle">LE NOSTRE APP PROPRIETARIE</h1>
                <div className="containerApp">

                    <div className="redirectDiv">
                        <img src="/image/sweet_home.webp" alt="Sweet Home" className="imageApp"></img>
                        <div id="overDivSH">
                            <a target="_blank" href="https://sweethomeforvantage.com/" id="redirectArrowSH"><IoIosRedo/></a>
                        </div>
                    </div>
                    <div className="appDiv">
                        <h3 className="appTitle" >Sweet Home</h3>
                        <p className="appDescription">Sweet home ti consente di gestire, controllare e monitorare i sistemi di automazione Vantage Infusion con dispositivi iOS e Android. </p>
                        <p className="appDescription">Sweet Home offre la possibilità di realizzare un pannello di controllo con grafica e funzioni completamente personalizzate secondo le richieste del cliente.</p>
                    </div>
                    <hr className="hrSeparator"></hr>
                    <div className="redirectDiv">
                        <img src="/image/voice_gate.webp" alt="Voice Gate" className="imageApp"></img>
                        <div id="overDivVG">
                            <a target="_blank" href="https://voicegate.lifetronic.it/" id="redirectArrowVG"><IoIosRedo/></a>
                        </div>
                    </div>
                    <div className="appDiv">
                        <h3 className="appTitle" >Voice Gate</h3>
                        <p className="appDescription">Voice Gate è un prodotto hardware e software che permette il controllo di Vantage Infusion tramite la propria voce. </p>
                        <p className="appDescription">Voice Gate è disponibile in più lingue:</p>
                    </div>
            
                    <div className="flagContainer">
                        <div className="flag1" style={{ background: `url("/image/flag/italy.webp")` }}></div>
                        <div className="flag" style={{ background: `url("/image/flag/united-kingdom.webp")` }}></div>
                        <div className="flag" style={{ background: `url("/image/flag/france.webp")` }}></div>
                        <div className="flag" style={{ background: `url("/image/flag/germany.webp")` }}></div>
                        <div className="flag" style={{ background: `url("/image/flag/india.webp")` }}></div>
                        <div className="flag" style={{ background: `url("/image/flag/spain.webp")` }}></div>

                    </div>
                </div>
                <h2 className="portfolioLink">Vuoi dare un'occhiata ai nostri use case?</h2>
                <div className="buttonRowProduct">
                    <Link to="/portfolio/?area=domotica&goup=y" className="ourProjectButton">I NOSTRI PROGETTI</Link>
                </div>
            </div>
        );
    }else if(selected == 3){
        return (
            <div className="containerDivDescriptionProduct">
                <div className="containerAreaData">
                
                    <h1 id="digital_title" title="digital signage" className="areaTitle" style={{
                                                    borderLeft: `4px solid #95C8E8`,
                                                }}>Digital Signage</h1>
                    <h4 className="areaService" style={{
                                                    color: `#95C8E8`,
                                                }}>Servizi offerti: Sviluppo contenuti, Vendita, Consulenza</h4>
                    <p className="areaDescription">Il Digital Signage è una nuova comunicazione in prossimità dei punti vendita o in spazi pubblici aperti o all'interno di edifici dove i cui contenuti vengono mostrati ai destinatari attraverso schermi elettronici o videoproiettori. Questa nuova tipologia di comunicazione offre la possibilità di modificare e trasformare in pochi instanti uno spazio pubblicitario in diverse realizzazioni, per cui avremo un maggiore rapporto qualità/prezzo. La nostra specialità sono gli espositori olografici: comunicare l’elemento sospeso in aria come un’immagine olografica.</p>
                </div>
                <AutoplaySlider 
                    play={true}
                    cancelOnInteraction={true}
                    interval={5000}
                    className="sliderSubproduct" 
                >
                    <div className="slide_prodotti" data-alt="Slider digital signage prima immagine" data-src="/image/slider/area/digital_signage/Slider_dreamoc.webp"></div>
                    <div className="slide_prodotti" data-alt="Slider digital signage seconda immagine" data-src="/image/slider/area/digital_signage/Slider_dreamoc_2.webp"></div>
                </AutoplaySlider>
                <h1 className="ourAppTitle">I NOSTRI DREAMOC</h1>
                <div className="dreamocWrap">
                    <div className="imageDreamoc" style={{background:"url(/image/dreamoc/pop3.webp)"}}></div>
                    <div className="dreamocDiv floatRight">
                        <h3 className="dreamocTitle" >POP 3</h3>
                        <p className="dreamocDescription">Display olografico di piccole dimensioni. Ideale per posizioni su scaffali o finestre. Collegamento a margherita di diversi display per un maggiore effetto. Nessun assemblaggio necessario. Colori personalizzabili. </p>
                    </div>
                </div>
                <div className="dreamocWrap">
                    <div className="imageDreamoc" style={{background:"url(/image/dreamoc/hd3.webp)"}}></div>
                    <div className="dreamocDiv floatLeft">
                        <h3 className="dreamocTitle" >HD3.2</h3>
                        <p className="dreamocDescription">Progettato specificamente per il marketing in-store nella vendita al dettaglio. Visualizzazione su 3 lati, perfetta per il posizionamento dei cappucci. Impila i prodotti direttamente sul supporto per acquisti facili. </p>
                    </div>
                </div>
                <div className="dreamocWrap">
                    <div className="imageDreamoc" style={{background:"url(/image/dreamoc/xl5.webp)"}}></div>
                    <div className="dreamocDiv floatRight">
                        <h3 className="dreamocTitle" >XL5</h3>
                        <p className="dreamocDescription">Display olografico 4K di medie dimensioni. Costruito per eventi, esposizioni o spazi commerciali più grandi. Touch screen interattivo. Nessun assemblaggio necessario. Scegli tra stand di branding o stand al dettaglio.</p>
                    </div>
                </div>
                <div className="dreamocWrap">

                    <div className="imageDreamoc" style={{background:"url(/image/dreamoc/xxl3.webp)"}}></div>
                    <div className="dreamocDiv floatLeft">
                        <h3 className="dreamocTitle" >XXL 3</h3>
                        <p className="dreamocDescription">Costruito per grandi spazi per eventi ed esposizioni. Visione su 4 lati. Costruito per funzionare 24 ore su 24, 7 giorni su 7. Ampie superfici laterali per il massimo del marchio esterno.</p>
                    </div>
                </div>
                <div className="dreamocWrap">
                    <div className="imageDreamoc" style={{background:"url(/image/dreamoc/diamond.webp)"}}></div>
                    <div className="dreamocDiv">
                        <h3 className="dreamocTitle floatRight" >DIAMOND</h3>
                        <p className="dreamocDescription">Ampio display olografico per eventi ed esposizioni. Touch screen interattivo. Ologrammi con risoluzione super nitida 4K. Fogli di branding interni/esterni. Visualizzazione completa su 4 lati.</p>
                    </div>
                </div>
                <div className="dreamocWrap">
                    <div className="imageDreamoc" style={{background:"url(/image/dreamoc/deep.webp)"}}></div>
                    <div className="dreamocDiv floatLeft">
                        <h3 className="dreamocTitle" >DEEPFRAME</h3>
                        <p className="dreamocDescription">Display rivoluzionario per la realtà mista. Proietta gli ologrammi in modo che appaiano direttamente nella realtà intorno a te. Utilizzare in grandi spazi per eventi, esposizioni o installazioni museali. Touch screen interattivo. Incredibile risoluzione OLED 4K. </p>
                    </div>
                </div>
                <h2 className="portfolioLink">Vuoi dare un'occhiata ai nostri use case?</h2>
                <div className="buttonRowProduct">
                    <Link to="/portfolio/?area=digital&goup=y" className="ourProjectButton">I NOSTRI PROGETTI</Link>
                </div>
            </div>
        );
    }else if(selected == 4){
        return (
        <div className="containerDivDescriptionProduct">
            <div className="containerAreaData">

                <h1 id="ricerca_title"  title="ricerca e sviluppo" className="areaTitle" style={{
                                                borderLeft: `4px solid #97BF0D`,
                                            }}>Ricerca e Sviluppo</h1>
                <h4 className="areaService" style={{
                                                color: `#97BF0D`,
                                            }}>Servizi offerti: Sviluppo, Sviluppo per terzi, Consulenza.</h4>
                <p className="areaDescription">In un settore innovativo come quello dell’automazione è essenziale essere sempre al passo con i tempi. Lifetronic propone attività alla ricerca di nuove tecnologie, miglioramenti di quelle già esistenti, per garantire ai clienti soluzioni all’avanguardia. Lifetronic sviluppa nuove tecnologie, investendo nella ricerca e nell’innovazione tecnologica.</p>
            </div>
            <AutoplaySlider 
                play={true}
                cancelOnInteraction={true}
                interval={5000}
                className="sliderSubproduct" 
            >
                <div className="slide_prodotti" data-alt="Slider ricerca e sviluppo prima immagine" data-src="/image/slider/area/ricerca_e_sviluppo/Slider_RaS.webp"></div>
                <div className="slide_prodotti" data-alt="Slider ricerca e sviluppo seconda immagine" data-src="/image/slider/area/ricerca_e_sviluppo/Slider_RaS_2.webp"></div>
                <div className="slide_prodotti" data-alt="Slider ricerca e sviluppo terza immagine" data-src="/image/slider/area/ricerca_e_sviluppo/Slider_RaS_3.webp"></div>
            </AutoplaySlider>
            <h2 className="portfolioLink">Vuoi dare un'occhiata ai nostri software?</h2>
            <div className="buttonRowProduct">
                <Link to="/portfolio/?area=ricerca&goup=y" className="ourProjectButton">I NOSTRI PROGETTI</Link>
            </div>
        </div>
        );
    }
    
};

export default productPage;
