import React, { useState, useEffect, useRef } from "react";
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import portfolio_description from '../portfolio_description.json';
import {GalleryCaption} from "../components";


const AutoplaySlider = withAutoplay(AwesomeSlider);

const StaticSlider = ({slider}) => {
    var images = "";
    var length=0;
   
    
    switch (slider) {

        case 'portfolio_sviluppo_software':

            images = portfolio_description.sviluppo_software;
            length= portfolio_description.sviluppo_software.length;
            //console.log(images);
            break;
        case 'portfolio_domotica':

            images = portfolio_description.domotica;
            length= portfolio_description.domotica.length;
            //console.log(images);
            break;
        case 'portfolio_digital_signage':

            images = portfolio_description.digital_signage;
            length= portfolio_description.digital_signage.length;
            //console.log(images);
            break;
        case 'portfolio_ricerca_e_sviluppo':

            images = portfolio_description.ricerca_e_sviluppo;
            length= portfolio_description.ricerca_e_sviluppo.length;
            //console.log(images);
            break;
    }
    const [current, setCurrent] = useState(0);
    const [title, setTitle] = useState([images[0].title]);
    const [description, setDescription] = useState([images[0].data]);
   
    //changeParaghraph(data);
    useEffect(
        () => {
            setTitle(
                images.map(
                    (slide) => (slide.title)
                )
            );
            setDescription(
                images.map(
                    (desc) => (desc.data)
                )
            );
            }, [images]
            
    );
   
    return(
        <div>
            <AutoplaySlider
                play={true}
                cancelOnInteraction={true}
                interval={0}
                onTransitionStart={slide => setCurrent(slide.nextIndex)}
                className="sliderHome"
                >
                {images.map((image, index) => {
                        
                    let src = "/image/slider/portfolio/"+image.image;
                    //console.log(src);
                    return (
                        <div key={index} data-src={src}>
                        </div>
                    );
                
                })}
            </AutoplaySlider>
            <GalleryCaption selected={current} title={title} description={description} area={slider}/>
        </div>
)
};

export default StaticSlider;

