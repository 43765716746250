import React, { useState} from "react";
import { LinkComponent } from "../utils/links";
import { Link } from "react-router-dom";
import logo from "../assets/image/logo.png";
import { useGlobalContext } from "../context";
import {FaBars} from "react-icons/fa"; 
import $ from 'jquery';
import { Search } from ".";
const Navbar = () => {
  const [searchOpen, setSearchOpen] = useState(0);
  $(document).on('click', '.aa-SubmitButton', function() {
    if(searchOpen == 0){
      $('.aa-Input').attr('style', 'display: inline-block!important');
      setSearchOpen(1);
    }else{
      $('.aa-Input').attr('style', 'display: none!important');
      setSearchOpen(0);
    }
  })

  const { openSidebar } = useGlobalContext();

    return (
      <nav className="nav">
        <div className="container nav-container">
          <header className="nav-header">
            <Link to="/" className="nav-brand">
              <div
              className="logo-img"
              style={{
                background: `url(${logo})`,
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
            </Link>
            <div className="nav-toggler">
              <button className="icon-btn btn nav-toggler" onClick={openSidebar}>
                <FaBars className="nav-icon" />
              </button>
            </div>
          </header>
          <LinkComponent classLink="nav-links" />
          <div id="search"></div>
        </div>
      </nav>
    );
  
};

export default Navbar;
