import React from "react";
import { Link } from "react-router-dom";


const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { name: "", email: "", message: "" };
    }

    /* Here’s the juicy bit for posting the form submission */

    handleSubmit = e => {
        if(this.state.name != "" && this.state.email != "" && this.state.message != "") {
            fetch("/", {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: encode({ "form-name": "contact", ...this.state })
            })
                .then(() => alert("Success!"))
                .catch(error => alert(error));

            e.preventDefault();
        }
    };

    handleChange = e => this.setState({ [e.target.name]: e.target.value });

    render() {
        const { name, email, message, object } = this.state;
        return (
            <form onSubmit={this.handleSubmit} className="contactForm">
                <div className="inlineFormRow">
                    <div className="inlineFormField">
                        <p>Nome</p>
                        <input className="inputForm" type="text" name="name" value={name} onChange={this.handleChange} />
                    </div>
                    <div className="inlineFormField">
                        <p>Email </p>
                        <input className="inputForm" type="email" name="email" value={email} onChange={this.handleChange} />
                    </div>
                </div>
                <div className="formField">
                    <p>Soggetto</p>
                    <input className="inputForm" type="text" name="object" value={object} onChange={this.handleChange} />
                </div>
                <div className="formField">
                    <p>Messaggio</p>
                    <textarea className="textAreaForm" name="message" value={message} onChange={this.handleChange} />
                </div>
                <div className="buttonRow">
                    <button className="formButton" type="submit">INVIA</button>
                </div>
                <p className="elseP">oppure</p>
                <div id="chiamaci" className="buttonRow">
                    <p><a href="tel:050877757"  style={{display: 'inline-flex',justifyContent: 'center',alignItems: 'center'}} className="callButton">CHIAMACI</a></p>
                </div>
            </form>
        );
    }
}


export default ContactForm;