
import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({text}) => <div className="MarkerMap" style={{
                                        background: `url("/image/LogoLife.png")` ,
                                        backgroundPosition: "center",
                                        backgroundSize: "contain",
                                        backgroundRepeat: "no-repeat",
                                      }}></div>;

const MyMap = ({props}) => {
    const [center, setCenter] = useState({lat: 43.67628694555431, lng: 10.431947634570534 });
    const [zoom, setZoom] = useState(15);
    return (
        <div style={{ height: '60vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyASU7B7k2P1Vec8dH2PjP98Mi4eFUArbm4' }}
          defaultCenter={center}
          defaultZoom={zoom}
        >
          <AnyReactComponent
            lat={43.6762}
            lng={10.4319}
            text="Lifetronic s.r.l."
          />
        </GoogleMapReact>
      </div>
    );
}

export default MyMap;
